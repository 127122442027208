import { Common } from '@thecvlb/design-system/lib/src';

import BackToMainWebsiteButton from 'widgets/wmOnboarding/parts/BackToMainWebsiteButton';

interface FailedStateProps {
  onClickOnBackButton: () => void;
}

const FailedState: React.FC<FailedStateProps> = ({ onClickOnBackButton }) => {
  return (
    <div className="flex flex-col gap-6 max-md:h-full">
      <h3 className="wm-signup-title text-center">
        You do not pre-qualify for the Triple Therapy Program at this time.
      </h3>
      <Common.Alert type="warning" colorableBackground>
        The Triple Therapy Program is not for everyone. Based on your responses to the questions,
        you are not a suitable candidate for this program.
      </Common.Alert>
      <BackToMainWebsiteButton onClick={onClickOnBackButton} />
    </div>
  );
};

export default FailedState;
